import React from 'react';
//import { FiMonitor , FiActivity, FiNavigation } from "react-icons/fi";
import { IoIosSchool , IoMdBulb, IoIosTime, IoIosChatboxes } from "react-icons/io";

const Service = () => {
    return (
        <div className="rn-service-area rn-section-gapBottom">
            {/* Start Service Area  */}
            <div className="rn-service-area">
                <div className="container">
                    <div className="row">

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <IoIosTime />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Experience</h4>
                 
                                        <p className="mb-0"><strong>Senior Web Developer</strong></p>
                                        <p>Master &amp; Mouse, 2015 to present</p>
                                        
                                        <p className="mb-0"><strong>Web Developer</strong></p>
                                        <p>PaperPlay, 2014 to 2015</p>

                                        <p className="mb-0"><strong>Junior Web Developer</strong></p>
                                        <p>ThinkTank Creative, 2012 to 2014</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <IoIosSchool />
                                    </div>
                                    <div className="content">
                                    <h4 className="title">Education</h4>
                                        <p className="mb-0"><strong>Bachelor of Information Science, BIS Multimedia (IT)</strong></p>
                                        <p>University of Pretoria, 2010 to 2012</p>
                                    </div>
                                </div>
                            </div>
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <IoIosChatboxes />
                                    </div>
                                    <div className="content">
                                    <h4 className="title">Languages</h4>
                                        <p>English, Afrikaans</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        {/* Start Single Service  */}
                        <div className="col-lg-4 col-md-12 col-12">
                            <div className="service wow fadeInLeft" data-wow-delay="200ms" data-wow-duration="1000ms">
                                <div className="inner">
                                    <div className="icon">
                                        <IoMdBulb />
                                    </div>
                                    <div className="content">
                                        <h4 className="title">Technical Skills</h4>
                                        <p>PHP, Bootstrap, HTML, CSS, Sass, JavaScript, jQuery, WordPress, React, Git, Gulp, NPM, RESTful APIs, Project Management, SEO.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Single Service  */}

                        

                    </div>
                </div>
            </div>
            {/* End Service Area  */}

        </div>
    )
}

export default Service;